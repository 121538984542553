(function () {
  'use strict';

  class ContactRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    contact() {
      return this.$resource(`${this.env.api}/contactus`);
    }

  }

  /**
   * @ngdoc service
   * @name components.service:ContactRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ContactRest', ContactRest);
}());
